import useAppTheme from '../design_system/hooks/useAppTheme';

import {css} from '@emotion/react';
import React from 'react';

export type Count = 3 | 4;
export interface Props {
  readonly count: Count;
  readonly minWidth: string;
  readonly stackMediaQuery: string;
  readonly children?: React.ReactNode;
}

export default function ResponsiveStackColumns(props: Props): JSX.Element {
  const {children, count, minWidth, stackMediaQuery} = props;
  const {spacing} = useAppTheme();
  const hideOnSmall = css({
    [stackMediaQuery]: {
      display: 'none',
    },
  });

  let columnTemplate: string;

  switch (count) {
    case 3:
      columnTemplate = `1.5fr repeat(3, minmax(${minWidth}, 3fr)) 1.5fr`;
      break;
    case 4:
      columnTemplate = `1fr repeat(4, minmax(${minWidth}, 2.5fr)) 1fr`;
  }

  return (
    <div
      css={css({
        display: 'grid',
        gap: spacing.x28,
        gridTemplateColumns: columnTemplate,
        justifyItems: 'center',
        marginTop: 60,
        paddingLeft: spacing.x20,
        paddingRight: spacing.x20,

        [stackMediaQuery]: {
          gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}, 1fr))`,
        },
      })}
    >
      <div css={hideOnSmall} />
      {children}
      <div css={hideOnSmall} />
    </div>
  );
}
