import SectionContainer from './SectionContainer';
import SectionTitle from './SectionTitle';
import {CSSStyles} from '../../types/CSSStyles';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';
import ResponsiveStackColumns, {Count} from '../layout/ResponsiveStackColumns';
import renderUserCards from '../user/raw/renderUserCards';
import {UserPublicProfile_UserFragment} from '../user/raw/UserPublicProfile_UserFragment';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly count: Count;
  readonly subtitle: string;
  readonly title: string;
  readonly users: UserPublicProfile_UserFragment[];
  readonly children?: React.ReactNode;
  readonly rootStyle?: CSSStyles;
}

export default function SectionUsers(props: Props): JSX.Element {
  const {children, count, rootStyle, subtitle, title, users} = props;
  const {palettes} = useAppTheme();
  return (
    <div
      css={[
        css({
          backgroundColor: palettes.background.neutral.disabled,
        }),
        rootStyle,
      ]}
      data-testid="users-section"
    >
      <SectionContainer>
        <SectionTitle
          subTitle={subtitle}
          title={title}
        />
        <ResponsiveStackColumns
          count={count}
          minWidth="300px"
          stackMediaQuery={useMediaQuery(MediaQuery.NonExtraLargeScreen)}
        >
          {renderUserCards(users)}
        </ResponsiveStackColumns>
        {children}
      </SectionContainer>
    </div>
  );
}
